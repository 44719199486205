<template>
    <section class="view-prof-section">
        <div class="container my-container">
            <div class="view-prof-content">
                <div class="row no-gutters">
                    <div class="col-sm-auto">
                    <div class="img-profile-container">

                    </div>
                    </div>
                    <div class="col-sm-7">
                    <div class="info-container">
                        <h3 class="u_name">
                        John Doe
                        </h3>
                        <p class="u_church">
                        Discovery Baptist Church
                        </p>
                        <ul class="token_like_container">
                        <li class="like">
                            <svg id="noun_like_1555605" xmlns="http://www.w3.org/2000/svg" width="26.5" height="23.639" viewBox="0 0 26.5 23.639">
                            <g id="Group_124" data-name="Group 124">
                                <path id="Path_90" data-name="Path 90" d="M6,26.7H1.571a.73.73,0,0,0-.738.738v11.8a.73.73,0,0,0,.738.738H6a.73.73,0,0,0,.737-.738v-11.8A.73.73,0,0,0,6,26.7Z" transform="translate(-0.833 -17.136)" fill="#315660"/>
                                <path id="Path_91" data-name="Path 91" d="M36.863,15.163a2.334,2.334,0,0,0-2.4-2.418H27.317a7.989,7.989,0,0,0,.611-6.273A3.183,3.183,0,0,0,25.122,4.2l-.021,0a1.545,1.545,0,0,0-1.635,1.414c-.184,1.864-1,5.16-2.171,6.33a10.374,10.374,0,0,1-3.227,2.082c-.2.1-.423.207-.657.324,0,.051.007.1.007.153v11.68l.5.171a20.344,20.344,0,0,0,7.35,1.482h5.777a2.334,2.334,0,0,0,2.4-2.418,2.606,2.606,0,0,0-.216-1.042,2.281,2.281,0,0,0,1.325-.733,2.478,2.478,0,0,0,.6-1.644,2.6,2.6,0,0,0-.215-1.039,2.334,2.334,0,0,0,1.925-2.379,2.5,2.5,0,0,0-.655-1.709A2.505,2.505,0,0,0,36.863,15.163Z" transform="translate(-10.364 -4.197)" fill="#315660"/>
                            </g>
                            </svg>                              
                            <p class="like-data">3 likes</p>
                        </li>
                        <li class="token">
                            <svg id="noun_token_2204477" xmlns="http://www.w3.org/2000/svg" width="32" height="25.129" viewBox="0 0 32 25.129">
                            <rect id="Rectangle_101" data-name="Rectangle 101" width="32" height="3.429" transform="translate(0 21.7)" fill="#315660"/>
                            <path id="Path_958" data-name="Path 958" d="M12.365,37.741A9.854,9.854,0,0,1,11.815,35H8.035a13.638,13.638,0,0,0,.85,4.2Z" transform="translate(-5.729 -20.729)" fill="#315660"/>
                            <path id="Path_959" data-name="Path 959" d="M49.831,42.915l-3.494-1.427a9.85,9.85,0,0,1-1.795,2.578h4.691Q49.56,43.5,49.831,42.915Z" transform="translate(-21.375 -23.509)" fill="#315660"/>
                            <path id="Path_960" data-name="Path 960" d="M52.74,35H48.96a9.876,9.876,0,0,1-.52,2.649l3.5,1.43A13.591,13.591,0,0,0,52.74,35Z" transform="translate(-23.046 -20.729)" fill="#315660"/>
                            <path id="Path_961" data-name="Path 961" d="M15.761,14.605l2.683,2.683a9.944,9.944,0,0,1,2.235-1.5l-1.47-3.5A13.8,13.8,0,0,0,15.761,14.605Z" transform="translate(-9.04 -10.993)" fill="#315660"/>
                            <path id="Path_962" data-name="Path 962" d="M15.535,44.132a9.852,9.852,0,0,1-1.754-2.491L10.3,43.1q.248.525.54,1.031Z" transform="translate(-6.702 -23.575)" fill="#315660"/>
                            <path id="Path_963" data-name="Path 963" d="M25.108,14.372a9.854,9.854,0,0,1,2.737-.55v-3.8a13.618,13.618,0,0,0-4.207.846Z" transform="translate(-12.416 -10.025)" fill="#315660"/>
                            <path id="Path_964" data-name="Path 964" d="M19.945,34.266H30.873A8.71,8.71,0,0,0,34.2,27.409a8.788,8.788,0,0,0-17.577,0A8.709,8.709,0,0,0,19.945,34.266Zm.143-8.225a.571.571,0,0,1,.528-.353h2.656l1.633-3.122a.572.572,0,0,1,.506-.306h.079a.572.572,0,0,1,.506.306l1.633,3.122h2.656a.571.571,0,0,1,.4.975L28.55,28.8l1.071,2.856a.572.572,0,0,1-.767.722l-3.4-1.513-3.4,1.513a.572.572,0,0,1-.767-.723L22.351,28.8l-2.139-2.14A.572.572,0,0,1,20.088,26.041Z" transform="translate(-9.409 -13.709)" fill="#315660"/>
                            <path id="Path_965" data-name="Path 965" d="M10.187,21.331,13.7,22.766a9.94,9.94,0,0,1,1.547-2.322l-2.683-2.683A13.774,13.774,0,0,0,10.187,21.331Z" transform="translate(-6.652 -13.34)" fill="#315660"/>
                            <path id="Path_966" data-name="Path 966" d="M8.025,29.939h3.8a9.849,9.849,0,0,1,.516-2.646L8.824,25.857A13.641,13.641,0,0,0,8.025,29.939Z" transform="translate(-5.725 -16.81)" fill="#315660"/>
                            <path id="Path_967" data-name="Path 967" d="M49.962,21.21a13.759,13.759,0,0,0-2.322-3.449l-2.683,2.683a9.946,9.946,0,0,1,1.505,2.235Z" transform="translate(-21.553 -13.34)" fill="#315660"/>
                            <path id="Path_968" data-name="Path 968" d="M51.869,25.638l-3.5,1.47a9.855,9.855,0,0,1,.55,2.737h3.8A13.668,13.668,0,0,0,51.869,25.638Z" transform="translate(-23.015 -16.716)" fill="#315660"/>
                            <path id="Path_969" data-name="Path 969" d="M44.485,14.564a13.759,13.759,0,0,0-3.57-2.377L39.48,15.7A9.932,9.932,0,0,1,41.8,17.248Z" transform="translate(-19.206 -10.952)" fill="#315660"/>
                            <path id="Path_970" data-name="Path 970" d="M27.583,32.886l-.721,1.924,2.354-1.047a.569.569,0,0,1,.232-.049h.071a.569.569,0,0,1,.232.049l2.354,1.047-.721-1.924a.572.572,0,0,1,.131-.6l1.425-1.425H31.317a.572.572,0,0,1-.506-.306l-1.327-2.536-1.326,2.536a.572.572,0,0,1-.506.306H26.028l1.425,1.425A.575.575,0,0,1,27.583,32.886Z" transform="translate(-13.441 -17.735)" fill="#315660"/>
                            <path id="Path_971" data-name="Path 971" d="M37.081,10.824a13.634,13.634,0,0,0-4.081-.8v3.8a9.849,9.849,0,0,1,2.646.516Z" transform="translate(-16.429 -10.025)" fill="#315660"/>
                            </svg>
                            <p class="like-data">5</p>                              
                        </li>
                        </ul>

                        <ul class="badge-container">
                        <li class="badge-item">
                            <img :src="require('../assets/img/profile/bdg_1.png')" alt="" class="img-fluid">
                        </li>
                        <li class="badge-item">
                            <img :src="require('../assets/img/profile/bdg_2.png')" alt="" class="img-fluid">
                        </li>
                        <li class="badge-item">
                            <img :src="require('../assets/img/profile/bdg_3.png')" alt="" class="img-fluid">
                        </li>
                        <li class="badge-item">
                            <img :src="require('../assets/img/profile/bdg_4.png')" alt="" class="img-fluid">
                        </li>
                        <li class="badge-item">
                            <img :src="require('../assets/img/profile/bdg_5.png')" alt="" class="img-fluid">
                        </li>
                        </ul>
                    </div>
                    </div>
                    <div class="col-sm-auto">
                    <button type="button" class="btn btn-send-msg">Send Message</button>
                    </div>
                </div>

                <div class="row no-gutters">
                    <div class="col-sm-6">
                    <div class="confirm_u_container">
                        <p class="title_label">Confirmed by</p>
                        <ul class="u_container">
                        <li class="u_item">
                            <div class="u_img-container"></div>
                            <p class="u_name">
                            John Brook
                            </p>
                            <p class="u_status">
                            <span><i class="fas fa-check-circle"></i></span>
                            Confirmed
                            </p>
                        </li>
                        <li class="u_item">
                            <div class="u_img-container"></div>
                            <p class="u_name">
                            John Brook
                            </p>
                            <p class="u_status">
                            <span><i class="fas fa-check-circle"></i></span>
                            Confirmed
                            </p>
                        </li>
                        <li class="u_item">
                            <div class="u_img-placeholder"></div>
                            <p class="u_name">
                            Member
                            </p>
                            <p class="u_status">
                            Wiating...
                            </p>
                        </li>
                        </ul>
                    </div>
                    </div>
                    <div class="col-sm-6">
                    <div class="bio-des-container">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus quis varius quam. Lorem sed risus ultricies tristique nulla aliquet enim tortor. Nascetur ridiculus mus mauris vitae ultricies leo integer malesuada. </p>
                    </div>
                    </div>
                </div>

                <div class="row no-gutters">
                    <div class="title-container">
                    <h3>Articles I'm Sharing</h3>
                    </div>
                    <div class="articles-contianer">
                    <div class="row no-gutters">
                        <div class="swiper-container-decor">
                            <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <div class="arcticle-book-item">
                                <div class="img-container">
                                    <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                                </div>
                                <div class="book-info-container">
                                    <p class="title-article">
                                    365 Read-Aloud Bedtime Bible...
                                    </p>
                                    <p class="author">
                                    Daniel Partner
                                    </p>
                                    <div class="rating">
                                    <ul>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="far fa-star"></i></li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="arcticle-book-item">
                                <div class="img-container">
                                    <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                                </div>
                                <div class="book-info-container">
                                    <p class="title-article">
                                    365 Read-Aloud Bedtime Bible...
                                    </p>
                                    <p class="author">
                                    Daniel Partner
                                    </p>
                                    <div class="rating">
                                    <ul>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="far fa-star"></i></li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="arcticle-book-item">
                                <div class="img-container">
                                    <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                                </div>
                                <div class="book-info-container">
                                    <p class="title-article">
                                    365 Read-Aloud Bedtime Bible...
                                    </p>
                                    <p class="author">
                                    Daniel Partner
                                    </p>
                                    <div class="rating">
                                    <ul>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="far fa-star"></i></li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="arcticle-book-item">
                                <div class="img-container">
                                    <img :src="require('../assets/img/browse/Image 21.png')" alt="" class="img-fluid">
                                </div>
                                <div class="book-info-container">
                                    <p class="title-article">
                                    365 Read-Aloud Bedtime Bible...
                                    </p>
                                    <p class="author">
                                    Daniel Partner
                                    </p>
                                    <div class="rating">
                                    <ul>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="fas fa-star"></i></li>
                                        <li><i class="far fa-star"></i></li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div class="swiper-button-next"></div>
                            <div class="swiper-button-prev"></div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="row no-gutters">
                    <div class="title-container">
                    <h3>My Book Reviews</h3>
                    </div>

                    <div class="book_article_container">
                    <ul>
                        <li class="review_book_item">
                        <div class="row no-gutters">
                            <div class="col-sm-2">
                            <div class="img-book-container">
                                <img :src="require('../assets/img/profile/rev_1.png')" alt="" class="img-fluid">
                            </div>
                            </div>
                            <div class="col-sm-10">
                            <div class="book-info-container">
                                <p class="book-name">
                                Bookname
                                </p>
                                <p class="book-author">
                                Sally Lloyd-Jones
                                </p>
                                <p class="label-rate">
                                My Rate and Review
                                </p>
                                <div class="star-rate">
                                <ul>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="far fa-star"></i></li>
                                </ul>
                                </div>
                                <p class="comment-review">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus quis varius quam. 
                                Lorem sed risus ultricies tristique nulla aliquet enim tortor. Nascetur ridiculus mus mauris vitae ultricies leo integer malesuada. 
                                </p>
                            </div>
                            </div>
                        </div>
                        <hr>
                        </li>
                        <li class="review_book_item">
                        <div class="row no-gutters">
                            <div class="col-sm-2">
                            <div class="img-book-container">
                                <img :src="require('../assets/img/profile/rev_1.png')" alt="" class="img-fluid">
                            </div>
                            </div>
                            <div class="col-sm-10">
                            <div class="book-info-container">
                                <p class="book-name">
                                Bookname
                                </p>
                                <p class="book-author">
                                Sally Lloyd-Jones
                                </p>
                                <p class="label-rate">
                                My Rate and Review
                                </p>
                                <div class="star-rate">
                                <ul>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="far fa-star"></i></li>
                                </ul>
                                </div>
                                <p class="comment-review">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus quis varius quam. 
                                Lorem sed risus ultricies tristique nulla aliquet enim tortor. Nascetur ridiculus mus mauris vitae ultricies leo integer malesuada. 
                                </p>
                            </div>
                            </div>
                        </div>
                        <hr>
                        </li>
                        <li class="review_book_item">
                        <div class="row no-gutters">
                            <div class="col-sm-2">
                            <div class="img-book-container">
                                <img :src="require('../assets/img/profile/rev_1.png')" alt="" class="img-fluid">
                            </div>
                            </div>
                            <div class="col-sm-10">
                            <div class="book-info-container">
                                <p class="book-name">
                                Bookname
                                </p>
                                <p class="book-author">
                                Sally Lloyd-Jones
                                </p>
                                <p class="label-rate">
                                My Rate and Review
                                </p>
                                <div class="star-rate">
                                <ul>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="far fa-star"></i></li>
                                </ul>
                                </div>
                                <p class="comment-review">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus quis varius quam. 
                                Lorem sed risus ultricies tristique nulla aliquet enim tortor. Nascetur ridiculus mus mauris vitae ultricies leo integer malesuada. 
                                </p>
                            </div>
                            </div>
                        </div>
                        <hr>
                        </li>
                    </ul>

                    <div class="d-grid gap-5 col-md-6 mx-auto">
                        <button class="btn btn-load-more" type="button" data-bs-toggle="modal" data-bs-target="#modal_notif_congrats">Load More</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>